import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import userThunkActions from "../../redux/slices/user/thunk/userThunk";
import userApi from "../../services/apiService/api/userApi";
import { isEmpty } from "lodash";
import { dispatchNotification, eraseCookie } from "../../helper/utils";

const JPWRoutes = () => {
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const queryParams = new URLSearchParams(location.search);
  let session = queryParams.get("session");
  session = decodeURIComponent(encodeURIComponent(session));
  session = session.replace(/ /g, "+");
  function wait(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useEffect(() => {
    setIsLoading(true);
    eraseCookie("token");
    const fetchDataFromApi = async () => {
      let url = window.location.origin.includes("localhost")
        ? "https://jmdfieldforce.uat.ril.com"
        : window.location.origin;
      const response = await fetch(`${url}/api/jpw/authentication`, {
        headers: {
          token: session,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data?.status === "ERROR") {
        let message = "No user found";
        dispatchNotification({
          type: "error",
          timeout: 3000,
          message: data?.data?.errorMessage || message,
        });
        await wait(2000);
        dispatchNotification({
          type: "error",
          timeout: 3000,
          message: "Redirecting back to JPW application",
        });
        await wait(2000);
        userApi.logout();
        window.location.href = "https://jpw-sit.jio.com/fm/OnBoarding/";
      }
      if (data?.employeeId) {
        sessionStorage.setItem("session", session);
        sessionStorage.setItem("user_details", JSON.stringify(data));
        setIsLoggedIn(true);
        setIsLoading(false);
      }
    };
    fetchDataFromApi();
  }, []);

  if (isLoading) {
    return <></>;
  } else {
    return isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
  }
};

export default JPWRoutes;
